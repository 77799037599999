import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';
import { showNotification } from "./notificationSlice";
import store from "../index";
import CampaignService from "../../services/CampaignService";
import ScooterTransactionService from 'services/ScooterTransactionService';

export const getAllScooterTransactions = createAsyncThunk('scooterTransactions/getAllTransactions', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await ScooterTransactionService.getAllScooterTransactions(order, page, take, filter);
        if (response.status === 200) {
            console.log({
                transaction: response.data.data,
                total: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            });
            return {
                transaction: response.data.data,
                total: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            };
        } else {
            return rejectWithValue('Failed to fetch adminUsers');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

// export const addCampaign = createAsyncThunk('campaign/addAdminUsers', async (data, { rejectWithValue }) => {
//     try {
//         const response = await CampaignService.addCampaign(data);
//         if (response.status === 201) {
//             return response.data.data;
//         } else {
//             return rejectWithValue('Failed to add user');
//         }
//     } catch (error) {
//         return rejectWithValue(error.response?.data?.message || 'Network Error');
//     }
// });


// export const detailCampaign = createAsyncThunk('campaign/updateAdminUser', async (id, { rejectWithValue }) => {
//     try {
//         const response = await CampaignService.detailCampaign(id);
//         if (response.status === 200) {
//             return {
//                 campaignDetail: response.data.data,
//             };
//         } else {
//             return rejectWithValue('Failed to update user');
//         }
//     } catch (error) {
//         return rejectWithValue(error.response?.data?.message || 'Network Error');
//     }
// });

// export const deleteCampaign = createAsyncThunk('campaign/deleteAdminUser', async (id, { rejectWithValue }) => {
//     try {
//         const response = await CampaignService.deleteCampaign(id);
//         if (response.status === 200 || response.status === 204 || response.status === 201) {
//             return id;
//         } else {
//             return rejectWithValue('Failed to delete scooter model');
//         }
//     } catch (error) {
//         store.dispatch(showNotification({
//             message: 'Error',
//             description: error.response?.data?.message || 'Network Error',
//             type: 'error',
//         }));
//         return rejectWithValue(error.response?.data?.message || 'Network Error');
//     }
// });

export const scooterTransactionSlice = createSlice({
    name: 'scooterTransaction',
    initialState: {
        transaction: [],
        campaignDetails: null,
        total: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllScooterTransactions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllScooterTransactions.fulfilled, (state, action) => {
                state.transaction = action.payload.transaction;
                state.total = action.payload.total;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllScooterTransactions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
        // .addCase(deleteCampaign.pending, (state) => {
        //     state.loading = true;
        // })
        // .addCase(addCampaign.pending, (state) => {
        //     state.loading = true;
        // })
        // .addCase(addCampaign.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.campaign.push(action.payload);
        //     state.total += 1;
        // })
        // .addCase(addCampaign.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;
        // })
        // .addCase(detailCampaign.pending, (state) => {
        //     state.loading = true;
        //     state.campaignDetails = null;
        // })
        // .addCase(detailCampaign.fulfilled, (state, action) => {
        //     state.campaignDetails = action.payload.campaignDetail;
        //     state.loading = false
        // })
        // .addCase(detailCampaign.rejected, (state, action) => {
        //     state.error = action.payload;
        //     state.loading = false;
        // });
    }
});

export default scooterTransactionSlice.reducer;
