import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TicketService from "services/TicketService";
import UserService from "../../services/UserService";
import store from "../index";
import {showNotification} from "./notificationSlice";
import {deleteAdminUser} from "./adminUserSlice";
import i18n from "i18next";

export const getAllTicket = createAsyncThunk('models/getAllTicket', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await TicketService.getAllTicket(order, page, take, filter);
        if (response.status === 200) {
            return {
                tickets: response.data.data,
                totalTickets: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            }
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const finishTicket = createAsyncThunk('models/finishTicket', async (id, { rejectWithValue }) => {
    try {
        const response = await TicketService.finishTicket(id);
        if (response.status === 200 || response.status === 201) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const deleteTicket = createAsyncThunk('users/deleteTicket', async (id, { rejectWithValue }) => {
    try {
        const response = await TicketService.deleteTicket(id);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to delete scooter model');
        }
    } catch (error) {
        store.dispatch(showNotification({
            message: 'Error',
            description: i18n.t(error.response?.data?.message) || 'Network Error',
            type: 'error',
        }));
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const ticketSlice = createSlice({
    name: 'ticket',
    initialState: {
        tickets: [],
        totalTickets: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTicket.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllTicket.fulfilled, (state, action) => {
                state.tickets = action.payload.tickets;
                state.totalTickets = action.payload.totalTickets;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllTicket.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(finishTicket.pending, (state) => {
                state.loading = true;
            })
            .addCase(finishTicket.fulfilled, (state, action) => {
                state.tickets = state.tickets.filter(ticket => ticket.id !== action.payload);
                state.loading = false;
            })
            .addCase(finishTicket.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteTicket.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteTicket.fulfilled, (state, action) => {
                state.tickets = state.tickets.filter(ticket => ticket.id !== action.payload)
                state.totalTickets -= 1
                state.loading = false
            })
            .addCase(deleteTicket.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default ticketSlice.reducer;