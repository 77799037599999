import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';
import {showNotification} from "./notificationSlice";
import store from "../index";
import i18n from "i18next";

export const getAllAdminUsers = createAsyncThunk('adminUsers/getAllAdminUsers', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await UserService.getAllUsers(order, page, take, filter);
        if (response.status === 200) {
            return {
                adminUsers: response.data.data,
                total: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            };
        } else {
            return rejectWithValue('Failed to fetch adminUsers');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addAdminUsers = createAsyncThunk('adminUsers/addAdminUsers', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.addUser(data);
        if (response.status === 201) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const updateAdminUser = createAsyncThunk('adminUsers/updateAdminUser', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await UserService.updateUser(id, data);
        if (response.status === 200) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to update user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const deleteAdminUser = createAsyncThunk('adminUsers/deleteAdminUser', async (id, { rejectWithValue }) => {
    try {
        const response = await UserService.deleteUser(id);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to delete scooter model');
        }
    } catch (error) {
        store.dispatch(showNotification({
            message: 'Error',
            description: i18n.t(error.response?.data?.message) || 'Network Error',
            type: 'error',
        }));
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const adminUserSlice = createSlice({
    name: 'adminUsers',
    initialState: {
        adminUsers: [],
        total: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllAdminUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllAdminUsers.fulfilled, (state, action) => {
                state.adminUsers = action.payload.adminUsers;
                state.total = action.payload.total;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllAdminUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateAdminUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAdminUser.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.adminUsers.findIndex(user => user.id === action.payload.id);
                if (index !== -1) {
                    state.adminUsers[index] = action.payload;
                }
            })
            .addCase(updateAdminUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addAdminUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(addAdminUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.adminUsers.push(action.payload);
                state.total += 1;
            })
            .addCase(addAdminUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteAdminUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAdminUser.fulfilled, (state, action) => {
                state.adminUsers = state.adminUsers.filter(model => model.id !== action.payload)
                state.total -= 1
                state.loading = false
            })
            .addCase(deleteAdminUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default adminUserSlice.reducer;
