import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';
import { showNotification } from "./notificationSlice";
import store from "../index";
import i18n from "i18next";

export const getAllUsers = createAsyncThunk('users/getAllUsers', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await UserService.getAllUsers(order, page, take, filter);
        if (response.status === 200) {
            return {
                users: response.data.data,
                total: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
                filterValues: filter,
            };
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const addUser = createAsyncThunk('users/addUser', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.addUser(data);
        if (response.status === 201) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const recoveryUser = createAsyncThunk('users/recoveryUser', async (id, { rejectWithValue }) => {
    try {
        const response = await UserService.recoveryUser(id);
        if (response.status === 200) {
            return id;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});



export const updateUser = createAsyncThunk('users/updateUser', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await UserService.updateUser(id, data);
        if (response.status === 200) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to update user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (id, { rejectWithValue }) => {
    try {
        const response = await UserService.deleteUser(id);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to delete scooter model');
        }
    } catch (error) {
        store.dispatch(showNotification({
            message: 'Error',
            description: i18n.t(error.response?.data?.message) || 'Network Error',
            type: 'error',
        }));
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const forceDeleteUser = createAsyncThunk('users/forceDeleteUser', async (id, { rejectWithValue }) => {
    try {
        const response = await UserService.forceDeleteUser(id);
        console.log(response);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to delete scooter model');
        }
    } catch (error) {
        store.dispatch(showNotification({
            message: 'Error',
            description: i18n.t(error.response?.data?.message) || 'Network Error',
            type: 'error',
        }));
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});
export const userSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        total: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null,
        filterValues: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.users = action.payload.users;
                state.total = action.payload.total;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.users.findIndex(user => user.id === action.payload.id);
                if (index !== -1) {
                    state.users[index] = action.payload;
                }
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.loading = false;
                state.users.push(action.payload);
                state.total += 1;
            })
            .addCase(addUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.users = state.users.filter(model => model.id !== action.payload)
                state.total -= 1
                state.loading = false
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(recoveryUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(recoveryUser.fulfilled, (state, action) => {
                // İlgili kullanıcıyı bul ve isDeleted'ı false yap
                const userIndex = state.users.findIndex(model => model.id === action.payload);
                if (userIndex !== -1) {
                    state.users[userIndex].isDeleted = false;
                    state.total += 1;
                }

                state.loading = false; // Yükleme durumunu güncelle
            })
            .addCase(recoveryUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(forceDeleteUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(forceDeleteUser.fulfilled, (state, action) => {
                // İlgili kullanıcıyı bul ve isDeleted'ı false yap
                state.users = state.users.filter(model => model.id !== action.payload)
                state.total -= 1
                state.loading = false
            })
            .addCase(forceDeleteUser.rejected, (state, action) => {
                console.log("buraya geldi3");

                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default userSlice.reducer;
