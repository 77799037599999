import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogsService from "../../services/LogsService";

export const getByIdAdminLogs = createAsyncThunk(
    'stores/getByIdAdminLogs',
    async (param,  { rejectWithValue }) => {
        try {
            const response = await LogsService.getByAdminLogs(param.selectedUserId,param?.page,param?.take);
            if (response.status === 200) {
                return {
                    logs: response.data.data,
                    totalLogs: response.data.meta.itemCount,
                    page: response.data.meta.page,
                    take: response.data.meta.take,
                }
            } else {
                return rejectWithValue('Failed to fetch users');
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Network Error');
        }
    });

export const logsSlice = createSlice({
    name: 'logs',
    initialState: {
        logs: [],
        loading: false,
        error: null,
        totalLogs: 0,
        page: 1,
        take: 10,

    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getByIdAdminLogs.pending, (state) => {
                state.loading = true;
            })
            .addCase(getByIdAdminLogs.fulfilled, (state, action) => {
                state.logs = action.payload.logs;
                state.loading = false;
                state.totalLogs = action.payload.totalLogs;
                state.page = action.payload.page
                state.take = action.payload.take;
            })
            .addCase(getByIdAdminLogs.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default logsSlice.reducer;