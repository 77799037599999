import axios from 'axios';
import { FITI_FITI_API_URL } from './URL';
import { forceDeleteUser } from 'store/slices/userSlice';

const ScooterTransactionService = {
    getAllScooterTransactions: (order = 'DESC', page = 1, take = 10, filter = {}) => {
        return axios.get(`${FITI_FITI_API_URL}/scooter-transaction/all`, {
            params: { order:'DESC', page, take, ...filter }
        });
    },
    addCampaign: (data) => {
        return axios.post(`${FITI_FITI_API_URL}/campaign`, data);
    },
    detailCampaign: (id, data) => {
        return axios.get(`${FITI_FITI_API_URL}/campaign/${id}`);
    },
    deleteCampaign: (id) => {
        return axios.delete(`${FITI_FITI_API_URL}/campaign/${id}`);
    },

};

export default ScooterTransactionService;
