import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogsService from "../../services/LogsService";

export const getByIdTransactionLogs = createAsyncThunk(
    'stores/getByIdTransactionLogs',
    async ({ id, page, take},  { rejectWithValue }) => {
        try {
            const response = await LogsService.getTransactionLogs(id, page,take);
            if (response.status === 200) {
                return {
                    logs: response.data.data,
                    total: response.data.meta.itemCount,
                    page: response.data.meta.page,
                    take: response.data.meta.take,
                }
            } else {
                return rejectWithValue('Failed to fetch users');
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Network Error');
        }
    });

export const transactionLogSlice = createSlice({
    name: 'transactionLog',
    initialState: {
        logs: [],
        loading: false,
        error: null,
        total: 0,
        page: 1,
        take: 10,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getByIdTransactionLogs.pending, (state) => {
                state.loading = true;
            })
            .addCase(getByIdTransactionLogs.fulfilled, (state, action) => {
                state.logs = action.payload.logs;
                state.loading = false;
                state.total = action.payload.total;
                state.page = action.payload.page
                state.take = action.payload.take;
            })
            .addCase(getByIdTransactionLogs.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default transactionLogSlice.reducer;