import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ScooterService from "services/ScooterService";
import UserService from "services/UserService";
import { showNotification } from "./notificationSlice";

export const getAllScooters = createAsyncThunk('scooters/getAllScooters', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await ScooterService.getAllScooters(order, page, take, filter);
        if (response.status === 200) {
            return {
                scooters: response.data.data,
                totalScooters: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            }
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addScooters = createAsyncThunk('scooters/addScooters', async ({ data }, { rejectWithValue }) => {
    try {
        const response = await ScooterService.addScooters(data);
        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
})

export const updateScooters = createAsyncThunk('scooters/updateScooters', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await ScooterService.updateScooters(id, data);
        if (response.status === 200 || response.status === 204) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to update scooter model');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});
export const collectiveUpdateScooters = createAsyncThunk('scooters/collectiveUpdateScooters', async ({ ids, data }, { rejectWithValue }) => {
    try {
        const response = await ScooterService.collectiveUpdateScooters(ids, data);
        if (response.status === 200 || response.status === 204) {
            return { ids, data: data };
        } else {
            return rejectWithValue('Failed to update scooter model');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});




export const deleteScooters = createAsyncThunk('scooters/deleteScooters', async (id, { rejectWithValue }) => {
    try {
        const response = await ScooterService.deleteScooters(id);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to delete scooter model');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});
export const deleteSelectedScooters = createAsyncThunk(
    'scooters/deleteSelectedScooters',
    async (ids, { rejectWithValue }) => {
        try {
            const deletePromises = ids.map(async (id) => {
                const response = await ScooterService.deleteScooters(id);
                if (response.status === 200 || response.status === 204 || response.status === 201) {
                    return id;
                } else {
                    throw new Error(`${id}li scooter silinierken hata verdi`);
                }
            });
            const deletedIds = await Promise.all(deletePromises);
            return deletedIds;
        } catch (error) {
            return rejectWithValue(error.message || 'Network Error');
        }
    }
);


export const getAllScootersCode = createAsyncThunk('scooters/getAllScootersCode', async (_, { rejectWithValue }) => {
    try {
        const response = await ScooterService.getAllScootersCode();
        if (response.status === 200) {
            return {
                codes: response.data.data,
            }
        } else {
            return rejectWithValue('Failed to fetch getAllScootersCode');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const getLogsUsers = createAsyncThunk('scooters/getLogsUsers', async ({ order, page, take, selectedUserId }, { rejectWithValue }) => {
    try {

        const response = await ScooterService.getLogsUsers(order, page, take, selectedUserId);
        if (response.status === 200) {
            return {
                userLogs: response.data.data,
                total: response.data.meta.itemCount,
                page: response.data.meta.page,
            }
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const getLogsScooters = createAsyncThunk('scooters/getLogsScooters', async ({ order, page, take, selectedQRCode }, { rejectWithValue }) => {
    try {
        const response = await ScooterService.getLogsScooters(order, page, take, selectedQRCode);
        if (response.status === 200) {
            return {
                scooterLogs: response.data.data,
                total: response.data.meta.itemCount,
                page: response.data.meta.page,
            }
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const getAllScooterSummary = createAsyncThunk('scooters/getAllScooterSummary', async (_, { rejectWithValue }) => {
    try {
        const response = await ScooterService.getAllScooterSummary();
        if (response.status === 200) {
            return {
                scooterSummary: response.data.data,
            }
        } else {
            return rejectWithValue('Failed to fetch getAllScooterSummary');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const getAllUserSummary = createAsyncThunk('scooters/getAllUserSummary', async (_, { rejectWithValue }) => {
    try {
        const response = await UserService.getAllUserSummary();
        if (response.status === 200) {
            return {
                userSummary: response.data.data,
            }
        } else {
            return rejectWithValue('Failed to fetch getAllScooterSummary');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const checkScooter = createAsyncThunk('scooters/checkScooter', async (code, { rejectWithValue, dispatch }) => {
    try {
        const response = await ScooterService.checkScooter(code);
        if (response.status === 200 && response.data.success) {
            dispatch(showNotification({
                message: 'Bağlanıldı',
                description: "Scooter'a bağlanıldı.",
                type: 'success',
                key: 'unique_key_success'
            }));
            return {
                checkScooterSuccess: response.data.success,
            }
        } else {
            dispatch(showNotification({
                message: 'Bağlanılmadı',
                description: "Scooter'a bağlanılmadı.",
                type: 'error',
                key: 'unique_key_error'
            }));
            return rejectWithValue('Failed to fetch checkScooter');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});
export const scooterSlice = createSlice({
    name: 'scooters',
    initialState: {
        scooters: [],
        totalScooters: 0,
        loading: false,
        error: null,
        codes: null,
        userLogs: [],
        total: 0,
        scooterLogs: [],
        scooterSummary: [],
        userSummary: [],
        checkScooterData: null,
        checkScooterMessage: null,
        checkScooterSuccess: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllScooters.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllScooters.fulfilled, (state, action) => {
                state.scooters = action.payload.scooters;
                state.totalScooters = action.payload.totalScooters;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllScooters.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(addScooters.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(addScooters.fulfilled, (state, action) => {
                state.scooters.push(action.payload);
                state.totalScooters += 1;
                state.loading = false;
            })
            .addCase(addScooters.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateScooters.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateScooters.fulfilled, (state, action) => {
                const index = state.scooters.findIndex(model => model.id === action.payload.id);
                if (index !== -1) {
                    state.scooters[index] = { ...state.scooters[index], ...action.payload };
                }
                state.loading = false;
            })
            .addCase(updateScooters.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(collectiveUpdateScooters.pending, (state) => {
                state.loading = true;
            })
            .addCase(collectiveUpdateScooters.fulfilled, (state, action) => {
                const { ids, data } = action.payload;

                state.scooters = state.scooters.map(scooter =>
                    ids.includes(scooter.id)
                        ? {
                            ...scooter,
                            ...(data.batteryLevel !== null && data.batteryLevel !== undefined && { batteryLevel: data.batteryLevel }),
                            ...(data.status !== null && data.status !== undefined && { status: data.status }),
                            ...(data.store !== null && data.store !== undefined && { store: data.store })
                        }
                        : scooter
                );
                state.loading = false;
                state.loading = false;

            })
            .addCase(collectiveUpdateScooters.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteScooters.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteScooters.fulfilled, (state, action) => {
                state.scooters = state.scooters.filter(model => model.id !== action.payload)
                state.totalScooters -= 1
                state.loading = false
            })
            .addCase(deleteScooters.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteSelectedScooters.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSelectedScooters.fulfilled, (state, action) => {
                //TODO buraya bakılacak
                // state.scooters = state.scooters.filter(model => model.id !== action.payload)
                // state.totalScooters -= 1
                state.loading = false
            })
            .addCase(deleteSelectedScooters.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getAllScootersCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllScootersCode.fulfilled, (state, action) => {
                state.codes = action.payload;
            })
            .addCase(getAllScootersCode.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getLogsUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLogsUsers.fulfilled, (state, action) => {
                state.userLogs = action.payload.userLogs;
                state.total = action.payload.total;
                state.page= action.payload.page;
                state.loading = false;
            })
            .addCase(getLogsUsers.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getLogsScooters.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLogsScooters.fulfilled, (state, action) => {
                state.scooterLogs = action.payload.scooterLogs;
                state.total = action.payload.total;
                state.page= action.payload.page;
                state.loading = false;
            })
            .addCase(getLogsScooters.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getAllScooterSummary.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllScooterSummary.fulfilled, (state, action) => {
                state.scooterSummary = action.payload.scooterSummary;
                state.loading = false;
            })
            .addCase(getAllScooterSummary.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getAllUserSummary.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllUserSummary.fulfilled, (state, action) => {
                state.userSummary = action.payload.userSummary;
                state.loading = false;
            })
            .addCase(getAllUserSummary.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(checkScooter.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkScooter.fulfilled, (state, action) => {
                state.checkScooterData = action.payload.checkScooterData;
                state.checkScooterMessage = action.payload.checkScooterMessage;
                state.checkScooterSuccess = action.payload.checkScooterSuccess;
                state.loading = false;
            })
            .addCase(checkScooter.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default scooterSlice.reducer;