import axios from 'axios';
import { FITI_FITI_API_URL } from "./URL";

const FileService = {
    upload: async (file, cascadeId) => {
        try {
            const formData = new FormData();
            formData.append("uploadType", "SINGLE");
            formData.append("cascadeTopic", "campaign-image");
            formData.append("cascadeName", "CAMPAIGN");
            formData.append("cascadeId", cascadeId);
            formData.append("files", file[0]['originFileObj'], file[0]['name']);

            const response = await axios.post(
                `${FITI_FITI_API_URL}/file`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`, // Token'i localStorage'dan al
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response.data; // Başarılı yanıtı döndür
        } catch (error) {
            console.error("File upload failed:", error);
            throw error; // Hata fırlat
        }
    },

};

export default FileService;
