import React, {useEffect} from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY, UNAUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import {useDispatch, useSelector} from "react-redux";
import {fetchCities} from "../store/slices/citySlice";
import { setHiddenItems } from 'configs/NavigationConfig';

const Routes = () => {
	const dispatch = useDispatch();

	const token = useSelector(state => state.auth?.token);
	const cities = useSelector(state => state.city?.cities);

	try {
		const user = useSelector(state => state.auth.user);
		setHiddenItems(user);
	} catch (e) {
		console.log(e);
	}

	useEffect(() => {
		if (!cities.length) {
			dispatch(fetchCities());
		}
	}, [cities]);


	return (
		<RouterRoutes>
			{/* Protected routes */}
			<Route element={<ProtectedRoute />}>
				{token ? (
					<>
						<Route index element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
						{protectedRoutes.map((route, index) => (
							<Route
								key={route.key + index}
								path={route.path}
								element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
							/>
						))}
						<Route path="*" element={<Navigate to={AUTHENTICATED_ENTRY} replace />} />
					</>
				) : (
						<Route path="*" element={<Navigate to={UNAUTHENTICATED_ENTRY} replace />} />
				)}
			</Route>

			{/* Public routes */}
			<Route element={<PublicRoute />}>
				{publicRoutes.map(route => (
					<Route
						key={route.key}
						path={route.path}
						element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
					/>
				))}
				<Route path="*" element={<Navigate to={UNAUTHENTICATED_ENTRY} replace />} />
			</Route>
		</RouterRoutes>
	)
}

export default Routes