import axios from 'axios';
import {FITI_FITI_API_URL} from "./URL";
import store from 'store';
import {showNotification} from "../store/slices/notificationSlice";
import i18n from "i18next";

const AuthService = {
	login: (data) => {
		return axios.post(`${FITI_FITI_API_URL}/auth/login`, data);
	},
	logout: () => {
		const token = localStorage.getItem('AUTH_TOKEN');
		return axios.post(`${FITI_FITI_API_URL}/auth/logout`, {}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
	},
	changePassword: (data) => {
		//const token = localStorage.getItem('AUTH_TOKEN');
		return axios.patch(`${FITI_FITI_API_URL}/users/password`, data)
	},
	refreshToken: async () => {
		const refreshToken = localStorage.getItem('REFRESH_TOKEN');
		if (!refreshToken) {
			console.error("Refresh token mevcut değil.");
			handleTokenExpiry();
			throw new Error("Refresh token mevcut değil.");
		}

		try {
			const response = await axios.post(`${FITI_FITI_API_URL}/auth/refresh-token`, { refreshToken });
			const { token } = response.data.data;

			localStorage.setItem('AUTH_TOKEN', token);
			return token;
		} catch (error) {
			console.error("Refresh token hatası:", error.response || error.message || error);
			handleTokenExpiry();
			throw error;
		}
	},
	updateProfile: (data) => {
		return axios.patch(`${FITI_FITI_API_URL}/users`, data);
	}
};

const handleTokenExpiry = () => {
	localStorage.clear();
	window.location.href = '/login';
};

const isTokenExpired = (token) => {
	if (!token) return true;

	try {
		const payload = JSON.parse(atob(token.split('.')[1]));
		const currentTime = Math.floor(Date.now() / 1000);
		return payload.exp <= currentTime;
	} catch (error) {
		console.error("Token çözümleme hatası:", error);
		return true;
	}
};

export const setupInterceptors = (navigate) => {
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem('AUTH_TOKEN');
			console.log(isTokenExpired(token));
			if (token) {
				if (isTokenExpired(token)) {
					handleTokenExpiry();
					throw new axios.Cancel('Token expired, skipping request');
				}

				config.headers['Authorization'] = `Bearer ${token}`;
			}

			return config;
		},
		(error) => Promise.reject(error)
	);

	axios.interceptors.response.use(
		response => response,
		async error => {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;

				try {
					const response = await AuthService.refreshToken();
					const newToken = response.data.data.token;

					localStorage.setItem('AUTH_TOKEN', newToken);
					originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
					return axios(originalRequest);
				} catch (refreshError) {
					if (refreshError.response) {
						localStorage.clear();
						localStorage.removeItem('AUTH_TOKEN');
						localStorage.removeItem('REFRESH_TOKEN');
						localStorage.removeItem('USER_INFO');
						window.location.href = '/login';
					}
				}

			} else if (error.response && error.response.data && error.response.data.message) {
				let errorMessage = error.response.data.message;

				if (errorMessage.includes('|')) {
					const [errorCode, value] = errorMessage.split('|');

					errorMessage = i18n.t(errorCode, {
						defaultValue: errorCode,
						value: value
					});
				} else {
					errorMessage = i18n.t(errorMessage, {
						defaultValue: errorMessage
					});
				}

				store.dispatch(showNotification({
					message: 'Hata',
					description: errorMessage,
					type: 'error',
					key: 'unique_key_error'
				}));
			}
			return Promise.reject(error);
		}
	);
};

export default AuthService;
