import axios from 'axios';
import { FITI_FITI_API_URL } from './URL';

const ScooterService = {
    getAllScooters: (order = 'ASC', page = 1, take = 10, filter = {}) => {
        return axios.get(`${FITI_FITI_API_URL}/scooters/all`, {
            params: { order, page, take, ...filter }
        });
    },
    addScooters: (data) => {
        return axios.post(`${FITI_FITI_API_URL}/scooters`, data);
    },
    updateScooters: (id, data) => {
        return axios.patch(`${FITI_FITI_API_URL}/scooters/${id}`, data);
    },
    collectiveUpdateScooters: (ids, data) => {
        return axios.patch(`${FITI_FITI_API_URL}/scooters/collective`, { 'ids': ids, 'status': data.status, 'store': data.store, 'batteryLevel': data.batteryLevel });
    },

    deleteScooters: (id) => {
        return axios.delete(`${FITI_FITI_API_URL}/scooters/${id}`);
    },
    getAllScootersCode: () => {
        return axios.get(`${FITI_FITI_API_URL}/scooters/code`);
    },
    getScooterDetailGeneralInfo: (order = 'ASC', page = 1, take = 10, id) => {
        return axios.get(`${FITI_FITI_API_URL}/scooter-transaction/all?scooter=${id}`, {
            params: { order, page, take }
        });
    },
    getScooterDetailFault: (order = 'ASC', page = 1, take = 10, filter) => {
        return axios.get(`${FITI_FITI_API_URL}/fault/all`, {
            params: { order, page, take, ...filter }
        });
    },
    getLogsUsers: (order = 'ASC', page = 1, take = 10, selectedUserId) => {
        return axios.get(`${FITI_FITI_API_URL}/users/logs/${selectedUserId}`, {
            params: { order: 'DESC', page, take }
        })
    },

    getLogsScooters: (order = 'ASC', page = 1, take = 10, selectedQRCode) => {
        return axios.get(`${FITI_FITI_API_URL}/scooters/logs/${selectedQRCode}`, {
            params: { order: 'DESC', page, take  ,}
        })
    },
    getAllScooterSummary: () => {
        return axios.get(`${FITI_FITI_API_URL}/scooters/all/summary`);
    },
    faultFinish: (id) => {
        return axios.post(`${FITI_FITI_API_URL}/fault/finish/${id}`);
    },
    checkScooter: (code) => {
        return axios.get(`${FITI_FITI_API_URL}/scooters/check-scooter/${code}`);
    }
}

export default ScooterService;