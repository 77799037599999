import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setHiddenItems } from 'configs/NavigationConfig';
import AuthService from 'services/AuthService';

export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.login(data);
		if (response.status === 200) {
			const { token, refreshToken, user } = response.data.data;

			if (user.role === 'user') {
				return rejectWithValue('Login Failed');
			}

			localStorage.setItem('AUTH_TOKEN', token);
			localStorage.setItem('REFRESH_TOKEN', refreshToken);
			localStorage.setItem('USER_INFO', JSON.stringify(user));

			try {
				setHiddenItems(user);
			} catch (e) {
				console.log(e);
			}

			return { token, refreshToken, user };
		} else {
			return rejectWithValue('Login Failed');
		}
	} catch (error) {
		return rejectWithValue(error.response.data.message || 'Network Error');
	}
});

export const signOut = createAsyncThunk('auth/logout', async () => {
	localStorage.removeItem('AUTH_TOKEN');
	localStorage.removeItem('REFRESH_TOKEN');
	localStorage.removeItem('USER_INFO');

	try {
		return await AuthService.logout();
	} catch (e) { }
});

export const changePassword = createAsyncThunk('auth/changePassword', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.changePassword(data);
		if (response.status === 200) {
			return response.data.data
		} else {
			return rejectWithValue('Change Password Failed');
		}
	} catch (error) {
		return rejectWithValue(error.response.data.message || 'Network Error');
	}
});

export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.refreshToken();
		if (response.status === 200) {
			const { token } = response.data.data;
			return token;
		} else {
			return rejectWithValue('Refresh Token Failed');
		}
	} catch (error) {
		return rejectWithValue(error.response.data.message || 'Network Error');
	}
});



export const updateProfile = createAsyncThunk('auth/updateProfile', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.updateProfile(data);
		if (response.status === 200) {
			const updatedUser = response.data.data;
			const currentUser = JSON.parse(localStorage.getItem('USER_INFO')) || {};
			const mergedUser = { ...currentUser, ...updatedUser };
			localStorage.setItem('USER_INFO', JSON.stringify(mergedUser));

			return mergedUser;
		} else {
			return rejectWithValue('Profile Update Failed');
		}
	} catch (error) {
		return rejectWithValue(error.response.data.message || 'Network Error');
	}
});

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		token: localStorage.getItem('AUTH_TOKEN') || null,
		refreshToken: localStorage.getItem('REFRESH_TOKEN') || null,
		user: JSON.parse(localStorage.getItem('USER_INFO')) || null,
		loading: false,
		message: '',
		showMessage: false,
		redirect: ''
	},
	reducers: {
		authenticated: (state, action) => {
			state.loading = false;
			state.redirect = '/';
			state.token = action.payload.token;
			state.refreshToken = action.payload.refreshToken;
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload;
			state.showMessage = true;
			state.loading = false;
		},
		hideAuthMessage: (state) => {
			state.message = '';
			state.showMessage = false;
		},
		signOutSuccess: (state) => {
			state.loading = false;
			state.token = null;
			state.refreshToken = null;
			state.user = null;
			state.redirect = '/login';
		},
		showLoading: (state) => {
			state.loading = true;
		},
		signInSuccess: (state, action) => {
			state.loading = false;
			state.token = action.payload.token;
			state.refreshToken = action.payload.refreshToken;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true;
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false;
				state.token = action.payload.token;
				state.refreshToken = action.payload.refreshToken;
				state.user = action.payload.user;
				state.redirect = '/dashboards/default';
			})
			.addCase(signIn.rejected, (state, action) => {
				state.loading = false;
				state.message = action.payload;
				state.showMessage = true;
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false;
				state.token = null;
				state.refreshToken = null;
				state.user = null;
				state.redirect = '/login';
			})
			.addCase(signOut.rejected, (state, action) => {
				state.loading = false;
				state.message = action.payload;
				state.showMessage = true;
			})
			.addCase(changePassword.pending, (state) => {
				state.loading = true;
			})
			.addCase(changePassword.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload
			})
			.addCase(changePassword.rejected, (state, action) => {
				state.loading = false;
				state.message = action.payload;
				state.showMessage = true;
			})
			.addCase(refreshToken.fulfilled, (state, action) => {
				state.token = action.payload;
			})
			.addCase(refreshToken.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
			})
			.addCase(updateProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateProfile.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
			})
			.addCase(updateProfile.rejected, (state, action) => {
				state.loading = false;
				state.message = action.payload;
			});
	}
});

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
