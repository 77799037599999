import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ScooterService from "services/ScooterService";
import axios from "axios";


export const getScooterDetailFault = createAsyncThunk('faults/getScooterDetailFault', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await ScooterService.getScooterDetailFault(order, page, take, filter);
        if (response.status === 200) {
            return {
                fault: response.data.data,
                totalFaults: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            };
        } else {
            return rejectWithValue('Failed to getScooterDetailFault');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const faultFinish = createAsyncThunk('faults/faultFinish', async (id, { rejectWithValue }) => {
    try {
        const response = await ScooterService.faultFinish(id);
        if (response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to getScooterDetailFault');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const faultsSlice = createSlice({
    name: 'faults',
    initialState: {
        totalFaults: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null,
        fault: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScooterDetailFault.pending, (state) => {
                state.loading = true;
            })
            .addCase(getScooterDetailFault.fulfilled, (state, action) => {
                state.fault = action.payload.fault;
                state.totalFaults = action.payload.totalFaults;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getScooterDetailFault.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(faultFinish.pending, (state) => {
                state.loading = true;
            })
            .addCase(faultFinish.fulfilled, (state, action) => {
                const updatedFaults = state.fault.map(fault =>
                    fault.id === action.payload
                        ? { ...fault, status: 'done' }
                        : fault
                );
                state.fault = updatedFaults;
                state.loading = false;
            })
            .addCase(faultFinish.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default faultsSlice.reducer;