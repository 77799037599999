import axios from 'axios';
import { FITI_FITI_API_URL } from './URL';

const LogsService = {
    getByAdminLogs: (id, page, take) => {
        return axios.get(`${FITI_FITI_API_URL}/users/admin-logs/${id}`, {
            params: {
                page,
                take
            }
        });

    },
    getTransactionLogs: (id, page,take) => {
        return axios.get(`${FITI_FITI_API_URL}/scooter-transaction/logs/${id}`, {
            params: {
                page, take


            }
        });
    },
}

export default LogsService;